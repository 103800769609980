import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import head from 'lodash/head';
import includes from 'lodash/includes';
import unset from 'lodash/unset';
import toInteger from 'lodash/toInteger';
import pick from 'lodash/pick';
import map from 'lodash/map';
import { all, call, put, race, take, takeLatest, getContext, select, delay } from 'redux-saga/effects';
import JSZip from 'jszip';
import jwtDecode from 'jwt-decode';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { LocalError } from 'helpers/errorTypes';
import {
  decrypt, encrypt, hybridDecrypt, hybridEncrypt, symmetricDecrypt, symmetricEncrypt,
  generateHash, generateKeyPair, generateSecret,
  getKeyFromPem, getPrvKeyPem, getPubKeyPem,
} from 'helpers/crypto';
import clearStorages from 'helpers/clearStorages';
import { serializeQuery } from 'helpers/urlTools';
import { compactObject } from 'helpers/transformers';
import { decryptEncodedEncryptedHolderPassphrase } from 'helpers/wallet';
import SessionStorage from 'libs/SessionStorage';
import CountryLocalizationService from 'services/CountryLocalizationService';
import NotificationsService from 'services/NotificationsService';
import StorageExchangeTokenService from 'services/StorageExchangeTokenService';
import convertRefreshToken from 'services/migrationServices/convertRefreshToken';
import OidcService from 'services/OidcService';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import Information from 'modules/Information';
import CloudDrive from 'modules/CloudDrive';
import DataSources from 'modules/DataSources';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import messages from './messages';


function* convertPatientProfileRefreshToken(patientProfile, prvKeyObj, pubKeyObj) {
  try {
    const { patientProfileId, encryptedRefreshToken, storageProvider } = patientProfile;
    const refreshToken = yield call(decrypt, encryptedRefreshToken, prvKeyObj);
    const values = {
      refreshToken,
      storageProvider,
      scope    : 'PatientProfile',
      controlId: patientProfileId,
    };
    const { exchangeToken, storageAccount } = yield call(convertRefreshToken, values);
    const encryptedExchangeToken = yield call(hybridEncrypt, exchangeToken, pubKeyObj);
    yield put(actions.updatePatientProfile({
      encryptedExchangeToken,
      storageAccount,
    }));
    return encryptedExchangeToken;
  } catch (err) {
    return null;
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* clearCookies() {
  const cookies = yield getContext('cookies');
  cookies.remove('token', { path: '/' });
  cookies.remove('region', { path: '/' });
  cookies.remove('hash', { path: '/' });
}

function* setCookies({ token, expiresIn, hash, rememberMe }) {
  const cookies = yield getContext('cookies');
  const expires = rememberMe ? new Date((+moment().format('X') + expiresIn) * 1000) : undefined;
  const options = {
    path  : '/',
    expires,
    secure: !__DEV__,
  };
  if (token) cookies.set('token', token, options);
  if (hash) cookies.set('hash', hash, options);
  if (rememberMe) cookies.set('rememberMe', rememberMe, { path: '/', expires: new Date('2040-12-31') });
}

//----------------------------------------------------------------------------------------------------------------------

function* changeAidPassword() {
  try {
    yield put(App.actions.goToAid('change-password'));
  } catch (err) {
    yield call(App.dispatchError, err, messages);
  }
}


function* editAidAccount() {
  try {
    yield put(App.actions.goToAid('personal-information'));
  } catch (err) {
    yield call(App.dispatchError, err, messages);
  }
}


function* addAidOrganizationMembership() {
  try {
    yield put(App.actions.goToAid('organization/choose'));
  } catch (err) {
    yield call(App.dispatchError, err, messages);
  }
}


function* manageAidOrganizationMemberships() {
  try {
    yield put(App.actions.goToAid('organization-memberships', null));
  } catch (err) {
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchSecurityKeys() {
  return yield call(ApiService.regionalRequest, '/api/Security/Keys');
}


function* sendKeys(passphrase, hash, prvKeyPem, pubKeyPem) {
  const encryptedPassphrase = yield call(symmetricEncrypt, passphrase, hash);

  const recoveryCode = yield call(generateSecret);
  const { recoveryPublicKey, backupPublicKey } = yield call(fetchSecurityKeys);

  const recoveryPublicKeyObj = yield call(getKeyFromPem, recoveryPublicKey);
  const encryptedRecoveryCode = yield call(encrypt, recoveryCode, recoveryPublicKeyObj);

  const backupPublicKeyObj = yield call(getKeyFromPem, backupPublicKey);
  const encryptedBackupCode = yield call(encrypt, recoveryCode, backupPublicKeyObj);

  const encryptedRecoveryHash = yield call(symmetricEncrypt, hash, recoveryCode);

  const requestUrl = '/api/Account/me/Keys';
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : {
      encryptedPrivateKey: prvKeyPem,
      publicKey          : pubKeyPem,
      encryptedPassphrase,
      encryptedBackupCode,
      encryptedRecoveryCode,
      encryptedRecoveryHash,
    },
  });
  return { prvKeyPem, pubKeyPem, encryptedPassphrase };
}


function* generateKeys(passphrase, hash, keyObjs = null) {
  const { prvKeyObj, pubKeyObj } = keyObjs || (yield call(generateKeyPair));

  const [prvKeyPem, pubKeyPem] = yield all([
    call(getPrvKeyPem, prvKeyObj, passphrase),
    call(getPubKeyPem, pubKeyObj),
  ]);

  return yield call(sendKeys, passphrase, hash, prvKeyPem, pubKeyPem);
}

//----------------------------------------------------------------------------------------------------------------------

function* setAccountKeyPair(account, hash) {
  if (!account.encryptedPrivateKey) {
    throw new LocalError({ code: 'NoEncryptedPrivateKey' });
  }
  const { encryptedPassphrase } = account;
  const passphrase = yield call(symmetricDecrypt, encryptedPassphrase, hash);
  if (!passphrase) {
    if (process.env.BROWSER) {
      yield call(clearCookies);
      yield put(App.actions.goToAid('secrets-recovery'));
      yield take(App.actionTypes.GO_TO_AID_ERROR);
    }
    throw new LocalError({ code: 'InvalidPassphrase' });
  }
  account.keyPair = {
    prvKeyPem: account.encryptedPrivateKey,
    pubKeyPem: account.publicKey,
  };
  return { account, passphrase };
}

//----------------------------------------------------------------------------------------------------------------------

function* migrateToPermanentRefreshToken(payload) {
  const { dataSourceContourCloud, account, prvKeyObj } = payload;
  const { keyPair } = account;
  const pubKeyObjSelf = getKeyFromPem(keyPair.pubKeyPem);

  let bodyPayload;

  yield put(DataSources.actions.fetchVault());
  const fetchVaultResult = yield take([
    DataSources.actionTypes.FETCH_VAULT_SUCCESS,
    DataSources.actionTypes.FETCH_VAULT_ERROR,
  ]);
  if (fetchVaultResult.type === DataSources.actionTypes.FETCH_VAULT_ERROR) {
    throw fetchVaultResult.error;
  }
  const { vault } = fetchVaultResult.payload;
  if (vault[dataSourceContourCloud.externalDataSourceId]) {
    bodyPayload = decrypt(vault[dataSourceContourCloud.externalDataSourceId].lastUsedEncryptedPassword, prvKeyObj);
  }

  const body = {
    ...dataSourceContourCloud,
    payload: bodyPayload,
    scope  : 'Self',
  };

  yield put(DataSources.actions.getPermanentRefreshToken(body));
  const permanentRefreshTokenResult = yield take([
    DataSources.actionTypes.GET_PERMANENT_REFRESH_TOKEN_SUCCESS,
    DataSources.actionTypes.GET_PERMANENT_REFRESH_TOKEN_ERROR,
  ]);

  if (permanentRefreshTokenResult.type === DataSources.actionTypes.GET_PERMANENT_REFRESH_TOKEN_ERROR) {
    throw permanentRefreshTokenResult.error;
  }
  const { response: { dataSourceExchangeToken } } = permanentRefreshTokenResult.payload;

  const encryptedDataSourceExchangeToken = hybridEncrypt(dataSourceExchangeToken, pubKeyObjSelf);

  yield put(DataSources.actions.setDataSourceExchangeToken(
    dataSourceContourCloud.externalDataSourceId, encryptedDataSourceExchangeToken,
  ));
  const setDataSourceExchangeTokenResult = yield take([
    DataSources.actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_SUCCESS,
    DataSources.actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_ERROR,
  ]);
  if (setDataSourceExchangeTokenResult.type === DataSources.actionTypes.SET_DATA_SOURCE_EXCHANGE_TOKEN_ERROR) {
    throw setDataSourceExchangeTokenResult.error;
  }

  return encryptedDataSourceExchangeToken;
}


function* getExternalDataSourceAccessToken(payload) {
  const { encryptedDataSourceExchangeToken, prvKeyObj } = payload;
  yield put(DataSources.actions.getAccessToken(encryptedDataSourceExchangeToken, prvKeyObj));
  const getAccessTokenResult = yield take([
    DataSources.actionTypes.GET_ACCESS_TOKEN_SUCCESS,
    DataSources.actionTypes.GET_ACCESS_TOKEN_ERROR,
  ]);
  if (getAccessTokenResult.type === DataSources.actionTypes.GET_ACCESS_TOKEN_ERROR) {
    return null;
  }
  return getAccessTokenResult;
}


function* runFetchCCToken() {
  const keyPair = yield select(selectors.keyPair);
  const passphrase = yield select(selectors.passphrase);
  const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);
  const connectedDataSources = yield select(DataSources.selectors.connectedDataSources);
  const dataSourceContourCloud = connectedDataSources.find(
    (dataSource) => dataSource.dataSourceProvider === DataSources.constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD,
  );
  const { encryptedDataSourceExchangeToken } = dataSourceContourCloud;
  const getAccessTokenResult = yield call(
    getExternalDataSourceAccessToken,
    { encryptedDataSourceExchangeToken, prvKeyObj },
  );
  const token = getAccessTokenResult && getAccessTokenResult.payload.response;
  yield put(actions.getCCAccessTokenSuccess(token));
}


function* refreshCCTokenWorker(expiresIn) {
  if (!expiresIn) {
    return;
  }
  const interval = (expiresIn - Math.ceil(expiresIn / 4)) * 1000;
  while (true) {
    yield delay(interval);
    yield call(runFetchCCToken);
  }
}


function* getCCAccessToken({ payload }) {
  try {
    const { passphrase, account } = payload;
    if (!account.keyPair) {
      yield put(actions.getCCAccessTokenError(new LocalError({ code: 'NoKeyPair' })));
      return;
    }

    const prvKeyObj = getKeyFromPem(account.keyPair.prvKeyPem, passphrase);

    yield put(DataSources.actions.fetchConnectedDataSources());
    const resultConnectedDataSources = yield take(
      DataSources.actionTypes.FETCH_CONNECTED_DATA_SOURCES_SUCCESS,
      DataSources.actionTypes.FETCH_CONNECTED_DATA_SOURCES_ERROR,
    );
    if (resultConnectedDataSources.payload.pattern === DataSources.actionTypes.FETCH_CONNECTED_DATA_SOURCES_ERROR) {
      yield put(actions.getCCAccessTokenError(new LocalError({ code: 'DataSourcesError' })));
      return;
    }
    const { payload: { connectedDataSources = [] } = {} } = resultConnectedDataSources;
    const dataSourceContourCloud = connectedDataSources.find(
      (dataSource) => dataSource.dataSourceProvider === DataSources.constants.DATA_SOURCES_TYPE_NAME.CONTOUR_CLOUD,
    );
    if (!dataSourceContourCloud) {
      yield put(actions.getCCAccessTokenError(new LocalError({ code: 'NoContourCloudDataSource' })));
      return;
    }
    let { encryptedDataSourceExchangeToken } = dataSourceContourCloud;
    if (!encryptedDataSourceExchangeToken) {
      encryptedDataSourceExchangeToken = yield call(
        migrateToPermanentRefreshToken,
        { dataSourceContourCloud, account, prvKeyObj },
      );
    }

    if (!encryptedDataSourceExchangeToken) {
      yield put(actions.getCCAccessTokenError(new LocalError({ code: 'NoEncryptedDataSourceExchangeToken' })));
      return;
    }

    let getAccessTokenResult = yield call(getExternalDataSourceAccessToken, {
      encryptedDataSourceExchangeToken, prvKeyObj,
    });

    if (!getAccessTokenResult) {
      encryptedDataSourceExchangeToken = yield call(
        migrateToPermanentRefreshToken,
        { dataSourceContourCloud, account, prvKeyObj },
      );
      getAccessTokenResult = yield call(getExternalDataSourceAccessToken, {
        encryptedDataSourceExchangeToken, prvKeyObj,
      });
    }

    const token = getAccessTokenResult && getAccessTokenResult.payload.response;

    if (!token) {
      yield put(actions.getCCAccessTokenError(new LocalError({ code: 'NoContourCloudAccessToken' })));
      return;
    }

    yield put(actions.getCCAccessTokenSuccess(token));
    yield race([
      call(refreshCCTokenWorker, token.expiresIn),
      take(actionTypes.SIGN_OUT),
    ]);
  } catch (err) {
    yield put(actions.getCCAccessTokenError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* getPCHash() {
  const cookies = yield getContext('cookies');
  const hash = cookies.get('hash');
  if (!hash) {
    throw new LocalError({ code: 'NoHash' });
  }
  return hash;
}


function* findRegionByCountry(alpha2Code) {
  if (!alpha2Code) {
    return null;
  }
  const regions = yield select(App.selectors.regions);
  const region = find(regions, (r) => includes(r.countries, alpha2Code));
  if (!region) {
    throw new LocalError({ code: 'InvalidRegion' });
  }
  return region;
}


function* configureActiveProfileType(account) {
  let activeProfileType;
  switch (account.scope) {
    case constants.SCOPE_NAMES.CAREGIVER: {
      activeProfileType = constants.PROFILE_TYPES.CG;
      break;
    }
    case constants.SCOPE_NAMES.PERSONAL: {
      activeProfileType = constants.PROFILE_TYPES.PWD;
      break;
    }
    case constants.SCOPE_NAMES.PROFESSIONAL: {
      activeProfileType = constants.PROFILE_TYPES.HCP;
      break;
    }
    default:
      throw new LocalError({ code: 'InvalidScope' });
  }
  yield put(actions.setActiveProfileType(activeProfileType));
}


function* configureRegion(token) {
  if (!token) {
    throw new LocalError({ code: 'NoToken ' });
  }
  const tokenData = jwtDecode(token);
  const identityCountryCode = get(tokenData, 'identity_country_code');
  const region = yield call(findRegionByCountry, identityCountryCode);
  if (!region) {
    throw new LocalError({ code: 'InvalidRegion' });
  }
  yield put(App.actions.setRegion(region.name));
  const setRegionResult = yield take([
    App.actionTypes.SET_REGION_SUCCESS,
    App.actionTypes.SET_REGION_ERROR,
  ]);
  if (setRegionResult.type === App.actionTypes.SET_REGION_ERROR) {
    throw setRegionResult.error;
  }
}


function* fetchAccount() {
  const requestUrl = '/api/Account/me';
  const account = yield call(ApiService.regionalRequest, requestUrl);
  if (!account) {
    throw new LocalError({ code: 'NoAccount' });
  }
  return account;
}


function* fetchClinicMemberships() {
  const requestURL = '/api/Account/me/ClinicMembership';
  return yield call(ApiService.regionalRequest, requestURL);
}


function* redirectAfterAuth() {
  const getUrl = yield getContext('getUrl');
  const redirect = yield getContext('redirect');

  const returnPathname = SessionStorage.getItem('returnPathname');
  if (returnPathname) {
    SessionStorage.removeItem('returnPathname');
    redirect(returnPathname);
    return;
  }

  const activeProfileMainUrlRoute = yield select(selectors.activeProfileMainUrlRoute);
  const redirectUrl = getUrl(activeProfileMainUrlRoute.name, activeProfileMainUrlRoute.params);
  redirect(redirectUrl);
}


function* getMe() {
  try {
    const cookies = yield getContext('cookies');
    const token = cookies.get('token');
    const isAuthenticated = !!token;

    if (!isAuthenticated) {
      yield put(actions.getMeSuccess());
      return;
    }
    yield call(configureRegion, token);

    yield put(Information.actions.checkInformation());
    const checkInformationResult = yield take([
      Information.actionTypes.CHECK_INFORMATION_SUCCESS,
      Information.actionTypes.CHECK_INFORMATION_ERROR,
    ]);
    if (checkInformationResult.type === Information.actionTypes.CHECK_INFORMATION_ERROR) {
      const err = checkInformationResult.error;
      yield put(actions.getMeError(err));
      yield call(App.dispatchError, err, messages, null, true);
      return;
    }

    const hash = yield call(getPCHash);
    const account = yield call(fetchAccount);
    yield call(configureActiveProfileType, account);
    const { countryId, language } = account;
    const langId = cookies.get('langId');
    yield put(App.actions.setLocale(language));
    if (language !== langId) {
      yield put(App.actions.fetchLocalizationResources());
    }
    yield put(App.actions.fetchCountrySettings(countryId));
    yield take([App.actionTypes.FETCH_COUNTRY_SETTINGS_SUCCESS, App.actionTypes.FETCH_COUNTRY_SETTINGS_ERROR]);
    const countrySettings = yield select(App.selectors.countrySettings);
    const metricsUnits = pick(countrySettings, App.constants.METRICS_UNITS_TYPE_NAMES);
    if (!account.encryptedPrivateKey) {
      yield put(actions.getMeSuccess({
        account,
        isAuthenticated,
        metricsUnits,
      }));
    }
    yield call(setAccountKeyPair, account, hash);
    const { settings, hcpProfile, patientProfile, cgProfile } = account;
    unset(account, 'hcpProfile');
    unset(account, 'patientProfile');
    unset(account, 'cgProfile');

    if (patientProfile) {
      patientProfile.countryId = account.countryId;
    }

    const calls = [
      // put(App.actions.fetchLegalConsents(countryId)),
      // TODO: Why these action is here?
      put(DataSources.actions.fetchDataSources()),
    ];

    const takes = [
      // take([App.actionTypes.FETCH_LEGAL_CONSENTS_SUCCESS, App.actionTypes.FETCH_LEGAL_CONSENTS_ERROR]),
      take([DataSources.actionTypes.FETCH_DATA_SOURCES_SUCCESS, DataSources.actionTypes.FETCH_DATA_SOURCES_ERROR]),
    ];

    if (account.scope === constants.SCOPE_NAMES.PROFESSIONAL) {

      yield put(Information.actions.checkOrganizationMemberships());
      yield take([
        Information.actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS_SUCCESS,
        Information.actionTypes.CHECK_ORGANIZATION_MEMBERSHIPS_ERROR,
      ]);
      const selectedActiveOrganizationUID = yield select(selectors.activeOrganizationUID);
      const organizationMemberships = yield select(Information.selectors.organizationMemberships);
      let activeOrganizationUID = selectedActiveOrganizationUID || cookies.get('activeOrganizationUID');
      let activeOrganizationMembership = null;
      let activeClinicMembershipId = null;
      let activeClinicMembership = null;

      if (hcpProfile) {
        hcpProfile.clinicHcpMemberships = yield call(fetchClinicMemberships);
        const selectedActiveClinicMembershipId = yield select(selectors.activeClinicMembershipId);
        activeClinicMembershipId = selectedActiveClinicMembershipId
          || toInteger(cookies.get('activeClinicMembershipId'));

        if (activeClinicMembershipId) {
          activeClinicMembership = find(
            hcpProfile.clinicHcpMemberships,
            { clinicHcpMembershipId: activeClinicMembershipId },
          );
        } else {
          activeOrganizationMembership = find(
            organizationMemberships,
            { organizationUID: activeOrganizationUID },
          );
        }

        if (!activeClinicMembership && !activeOrganizationMembership) {
          if (hcpProfile.clinicHcpMemberships.length) {
            activeClinicMembership = head(hcpProfile.clinicHcpMemberships);
            activeClinicMembershipId = activeClinicMembership.clinicHcpMembershipId;
            activeOrganizationUID = activeClinicMembership.clinic.organizationUID;
          } else {
            activeOrganizationMembership = head(organizationMemberships);
            activeOrganizationUID = activeOrganizationMembership.organization.organizationUID;
          }
        }
      } else {
        activeOrganizationMembership = find(organizationMemberships, { organizationUID: activeOrganizationUID });
        if (!activeOrganizationMembership) {
          activeOrganizationMembership = head(organizationMemberships);
          activeOrganizationUID = activeOrganizationMembership.organization.organizationUID;
        }
      }
      yield put(actions.setActiveClinicMembershipId(activeClinicMembershipId, activeOrganizationUID));
    }

    yield all(calls);
    yield all(takes);

    const { glucoseLevelUnitTypes } = settings;

    if (glucoseLevelUnitTypes) {
      metricsUnits.bloodGlucoseConcentration = glucoseLevelUnitTypes;
    }

    yield put(actions.getMeSuccess({
      account,
      hcpProfile,
      patientProfile,
      cgProfile,
      isAuthenticated,
      metricsUnits,
    }));

    const locale = yield select(App.selectors.locale);
    yield put(App.actions.setLocale(locale));

    const alertsCalls = [
      put(App.actions.fetchSystemAlertsSettings()),
      put(App.actions.fetchSystemAlerts()),
    ];
    const alertsTakes = [
      take([App.actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS_SUCCESS, App.actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS_ERROR]),
      take([App.actionTypes.FETCH_SYSTEM_ALERTS_SUCCESS, App.actionTypes.FETCH_SYSTEM_ALERTS_ERROR]),
    ];
    yield all(alertsCalls);
    yield all(alertsTakes);

  } catch (err) {
    yield put(actions.getMeError(err));
    yield call(App.dispatchError, err, messages, null, true);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* runFetchToken() {
  const cookies = yield getContext('cookies');
  const currentToken = cookies.get('token');
  const rememberMe = cookies.get('rememberMe');
  const requestUrl = `/api/Token?token=${currentToken}`;
  const { token, expiresIn } = yield call(ApiService.regionalRequest, requestUrl);
  yield call(setCookies, { token, expiresIn, rememberMe });
}


function* refreshTokenWorker(expiresIn) {
  if (!expiresIn) {
    return;
  }
  const interval = (expiresIn - Math.ceil(expiresIn / 4)) * 1000;
  while (true) {
    try {
      yield delay(interval);
      yield call(runFetchToken);
    } catch (err) {
      yield put(actions.signOut());
    }
  }
}


function* checkTokensIntegrity(token) {
  const tokenData = jwtDecode(token);
  const aidTokenData = yield call(OidcService.getTokenData);
  return tokenData && aidTokenData
    && tokenData.identity_scope === aidTokenData.identity_scope
    && tokenData.identity_country_code === aidTokenData.identity_country_code;
}


function* fetchToken() {
  try {
    const cookies = yield getContext('cookies');
    const currentToken = cookies.get('token');
    if (!currentToken) {
      return;
    }
    const areTokensIntegral = yield call(checkTokensIntegrity, currentToken);
    if (!areTokensIntegral) {
      cookies.remove('token', { path: '/' });
      cookies.remove('region', { path: '/' });
      cookies.remove('hash', { path: '/' });
      OidcService.login();
      return;
    }
    const { expiresIn } = yield call(runFetchToken);
    yield race([
      call(refreshTokenWorker, expiresIn),
      take(actionTypes.SIGN_OUT),
    ]);
  } catch (err) {
    // Background process
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* resetLocale() {
  const locale = yield select(App.selectors.locale);
  moment.locale(locale);
  moment.defineLocale('en--account', null);
  if (locale !== 'en') {
    moment.defineLocale(`${locale}--account`, null);
  }
}


function* clearSession(preserveStorage = false) {
  yield all([
    call(clearStorages, preserveStorage),
    call(resetLocale),
    put(App.actions.setSignalRDisconnected()),
  ]);
}


function* signOut({ payload }) {
  try {
    if (!process.env.BROWSER) {
      return;
    }
    const { preserveStorage } = payload;
    yield all([
      call(OidcService.logout),
      call(clearSession, preserveStorage),
    ]);
  } catch (err) {
    yield put(actions.signOutError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* signIn() {
  try {
    const requestUrl = '/api/Token/idp';
    const aidToken = yield call(OidcService.getToken);
    if (!aidToken) {
      const err = new LocalError({ code: 'NoAidToken' });
      yield put(actions.signInError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }
    yield call(configureRegion, aidToken);
    const response = yield call(ApiService.regionalRequest, requestUrl, { method: 'POST' }, aidToken);
    const aidTokenData = yield call(OidcService.getTokenData);
    const hash = aidTokenData.pc_hash;

    const { token, expiresIn, changePassword: changePasswordReset } = response;
    yield put(actions.setChangePasswordReset(changePasswordReset));
    // const hash = yield call(generateHash, password, email);
    const rememberMe = false;
    yield call(setCookies, { token, expiresIn, hash, rememberMe });
    yield call(getMe);
    if (!changePasswordReset) {
      yield put(actions.getPassphrase());
    }
    // yield call(invitationsFromSessionStorage);
    yield call(redirectAfterAuth);

    yield put(actions.signInSuccess());

    yield race([
      call(refreshTokenWorker, expiresIn),
      take(actionTypes.SIGN_OUT),
    ]);
  } catch (err) {
    yield all([
      call(OidcService.login),
      call(clearSession, true),
    ]);
    // yield put(actions.signOut({ preserveStorage: true }));
    yield put(actions.signInError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* authCallback({ payload }) {
  const { code } = payload;
  if (code) {
    try {
      yield call(OidcService.loginCallback);
      yield call(signIn);
    } catch (err) {
      yield call(OidcService.login);
    }
  } else {
    yield call(OidcService.login);
  }
}

// TODO: Remove if confirmed

// function* authContourCloud({ payload }) {
//   try {
//     const { code, referenceId } = payload;
//
//     const requestURL = '/api/Token/contourCloud';
//     const redirectUrl = yield call(DataSources.sagas.getRedirectUrl);
//
//     const response = yield call(ApiService.regionalRequest, requestURL, {
//       method: 'POST',
//       body  : {
//         authorizationCode: code,
//         referenceId,
//         redirectUrl,
//       },
//     });
//
//     const { token, expiresIn } = response;
//     yield call(setCookies, { token, expiresIn });
//     yield call(getMe);
//     yield call(redirectAfterAuth);
//
//     yield put(actions.authContourCloudSuccess());
//
//     yield race([
//       call(refreshTokenWorker, expiresIn),
//       take(actionTypes.SIGN_OUT),
//     ]);
//   } catch (err) {
//     yield all([
//       call(OidcService.login),
//       call(clearSession),
//     ]);
//     // yield put(actions.signOut());
//     yield put(actions.authContourCloudError(err));
//     yield call(App.dispatchError, err, messages);
//   }
// }


function* elevatePermissions({ payload }) {
  try {
    const { password } = payload;
    // const elevatedPermissionsMode = yield select(selectors.elevatedPermissionsMode);
    const information = yield select(Information.selectors.information);
    const { email } = information || {};
    const hash = yield call(generateHash, password, email);
    const pcHash = yield call(getPCHash);
    if (hash !== pcHash) {
      const err = new LocalError({ code: 'WrongPassword' });
      yield put(actions.elevatePermissionsError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }
    yield put(actions.elevatePermissionsSuccess());
    yield delay(3 * 60 * 1000);
    yield put(actions.elevatePermissionsTimeout());
  } catch (err) {
    yield put(actions.elevatePermissionsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchPatientDataFromInvitation({ payload }) {
  if (!process.env.BROWSER) {
    return;
  }
  try {
    const { invitationCode, email } = payload;
    const requestUrl = `/api/SharingRequest/patient/${invitationCode}/${email}`;
    const patientDataFromInvitation = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.fetchPatientDataFromInvitationSuccess({ email, ...patientDataFromInvitation }));
  } catch (err) {
    yield put(actions.fetchPatientDataFromInvitationError(err));
  }
}


function* fetchPatientHealthDataFromInvitation() {
  if (!process.env.BROWSER) {
    return;
  }
  try {
    const requestUrl = '/api/SharingRequest/patient/healthData';
    const patientHealthDataFromInvitation = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.fetchPatientHealthDataFromInvitationSuccess(patientHealthDataFromInvitation));
  } catch (err) {
    yield put(actions.fetchPatientHealthDataFromInvitationError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* validateAccount() {
  try {
    const activeProfileMainUrlRoute = yield select(selectors.activeProfileMainUrlRoute);

    if (activeProfileMainUrlRoute.isFinal) {
      return;
    }

    const { name, params, alternates } = activeProfileMainUrlRoute;
    const route = yield select(App.selectors.route);

    if (name === route.name || (alternates && includes(alternates, route.name))) {
      return;
    }

    const getUrl = yield getContext('getUrl');
    const redirect = yield getContext('redirect');
    redirect(getUrl(name, params));
  } catch (err) {
    // yield put(actions.signOut());
    yield all([
      call(OidcService.login),
      call(clearSession, true),
    ]);
  }
}


function* validateSensitiveDataAccessAlert() {
  const cookies = yield getContext('cookies');
  const wasAlertDisplayed = cookies.get('sensitive-data-access-alert-displayed');
  if (wasAlertDisplayed) {
    return;
  }

  const countryId = yield select(selectors.countryId);
  const countries = yield select(App.selectors.countries);
  const country = find(countries, { countryId });
  if (country.alpha2Code !== 'es') {
    return;
  }

  yield put(App.actions.setAlert({
    type   : 'warning',
    message: messages.alerts.sensitiveDataAccess,
  }));
  cookies.set('sensitive-data-access-alert-displayed', true, { path: '/', expires: new Date('2040-12-31') });
}


function* checkPermissions({ payload }) {
  const getUrl = yield getContext('getUrl');
  const redirect = yield getContext('redirect');
  const pathname = yield getContext('pathname');
  const query = yield getContext('query');

  let returnPathname = '';
  if (pathname) {
    returnPathname += pathname;
    if (query) returnPathname += `?${serializeQuery(query)}`;
    returnPathname = encodeURIComponent(returnPathname);
  }
  const signInUrl = `${getUrl('sign-in')}${returnPathname && `?returnPathname=${returnPathname}`}`;
  try {
    const { rules } = payload;

    if (includes(rules, 'NOT_LOGGED_IN')) {
      const isAuthenticated = yield select(selectors.isAuthenticated);
      const isSignOutInProgress = yield select(selectors.isSignOutInProgress);
      if (isAuthenticated && !isSignOutInProgress) {
        const activeProfileMainUrlRoute = yield select(selectors.activeProfileMainUrlRoute);
        const redirectUrl = getUrl(activeProfileMainUrlRoute.name, activeProfileMainUrlRoute.params);
        redirect(redirectUrl);
        return;
      }
    }

    if (includes(rules, 'LOGGED_IN')) {
      const isAuthenticated = yield select(selectors.isAuthenticated);
      if (!isAuthenticated) {
        yield all([
          call(redirect, signInUrl),
          call(clearSession),
        ]);
        return;
      }
    }

    if (includes(rules, 'CG')) {
      const isCareGiver = yield select(selectors.isCareGiver);
      const isPatient = yield select(selectors.isPatient);
      if (!isCareGiver && !isPatient) {
        const hcpProfileMainUrlRoute = yield select(selectors.hcpProfileMainUrlRoute);
        const redirectUrl = getUrl(hcpProfileMainUrlRoute.name, hcpProfileMainUrlRoute.params);
        if (!redirectUrl) {
          yield call(validateAccount);
          return;
        }
        redirect(redirectUrl);
        return;
      }
    }

    if (includes(rules, 'PWD')) {
      const isPatient = yield select(selectors.isPatient);
      if (!isPatient) {
        const hcpProfileMainUrlRoute = yield select(selectors.hcpProfileMainUrlRoute);
        const redirectUrl = getUrl(hcpProfileMainUrlRoute.name, hcpProfileMainUrlRoute.params);
        if (!redirectUrl) {
          yield call(validateAccount);
          return;
        }
        redirect(redirectUrl);
        return;
      }
      yield put(actions.setActiveProfileType(constants.PROFILE_TYPES.PWD));
    }

    if (includes(rules, 'HCP') || includes(rules, 'CLINIC_ADMIN')) {
      const isHcp = yield select(selectors.isHcp);
      if (isHcp) {
        yield call(validateSensitiveDataAccessAlert);
      } else {
        const patientProfileMainUrlRoute = yield select(selectors.patientProfileMainUrlRoute);
        const redirectUrl = getUrl(patientProfileMainUrlRoute.name, patientProfileMainUrlRoute.params);
        redirect(redirectUrl);
        return;
      }
      if (includes(rules, 'CLINIC_ADMIN')) {
        const isClinicAdmin = yield select(selectors.isClinicAdmin);
        if (!isClinicAdmin) {
          const hcpProfileMainUrlRoute = yield select(selectors.hcpProfileMainUrlRoute);
          const redirectUrl = getUrl(hcpProfileMainUrlRoute.name, hcpProfileMainUrlRoute.params);
          redirect(redirectUrl);
          return;
        }
      }
      yield put(actions.setActiveProfileType(constants.PROFILE_TYPES.HCP));
    }

  } catch (err) {
    yield all([
      call(redirect, signInUrl),
      call(clearSession),
    ]);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* generateKeysPem({ payload }) {
  try {
    let { passphrase } = payload;
    if (!passphrase) {
      passphrase = yield call(generateSecret);
    }

    const hash = yield call(getPCHash);
    const { prvKeyPem, pubKeyPem, encryptedPassphrase } = yield call(generateKeys, passphrase, hash);

    yield put(actions.generateKeysPemSuccess(prvKeyPem, pubKeyPem, passphrase, encryptedPassphrase));
  } catch (err) {
    yield put(actions.generateKeysPemError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* getPassphrase() {
  try {
    const hash = yield call(getPCHash);
    const account = yield select(selectors.account);
    const { encryptedPassphrase } = account;
    const passphrase = symmetricDecrypt(encryptedPassphrase, hash);
    yield put(actions.getPassphraseSuccess(passphrase));
  } catch (err) {
    yield put(actions.getPassphraseError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* setActiveClinicMembershipId({ payload }) {
  try {
    const cookies = yield getContext('cookies');
    const { activeClinicMembershipId, organizationUID } = payload;
    if (activeClinicMembershipId) {
      cookies.set('activeClinicMembershipId', activeClinicMembershipId, {
        path   : '/',
        expires: new Date('2040-12-31'),
      });
    } else {
      cookies.remove('activeClinicMembershipId', { path: '/' });
    }
    cookies.set('activeOrganizationUID', organizationUID, {
      path   : '/',
      expires: new Date('2040-12-31'),
    });
  } catch (err) {
    // Background action
  }
}


function* switchProfile() {
  try {
    // const cookies = yield getContext('cookies');
    // cookies.remove('token', { path: '/' });
    // cookies.remove('region', { path: '/' });
    // cookies.remove('hash', { path: '/' });
    yield put(App.actions.goToAid('switch-identity', { name: 'sign-in' }));
  } catch (err) {
    // Background action
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* updateAccountSettings({ payload }) {
  try {
    const { settings } = payload;

    const settingsRequestUrl = '/api/Account/me/settings';
    const responseSettings = yield call(ApiService.regionalRequest, settingsRequestUrl, {
      method: 'PUT',
      body  : settings,
    });

    yield put(actions.updateAccountSettingsSuccess(responseSettings));
    const locale = yield select(App.selectors.locale);
    yield put(App.actions.setLocale(locale));
  } catch (err) {
    yield put(actions.updateAccountSettingsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* deleteAccount() {
  try {
    yield put(actions.setElevatedPermissionsMode(constants.ADDITIONAL_CLAIMS.deleteAccount));
    const [elevatePermissionsSuccess] = yield race([
      take(actionTypes.ELEVATE_PERMISSIONS_SUCCESS),
      take(actionTypes.SET_ELEVATED_PERMISSIONS_MODE),
    ]);
    if (!elevatePermissionsSuccess) {
      yield put(actions.deleteAccountError());
      return;
    }

    const requestUrl = '/api/Account/me';
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'DELETE',
    });

    yield put(actions.setElevatedPermissionsMode(null));
    yield put(actions.deleteAccountSuccess());
    yield put(actions.signOut());
  } catch (err) {
    yield put(actions.deleteAccountError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* updatePatientProfileStorageToken({ payload }) {
  try {
    const {
      storageProvider,
      storageAccount,
      exchangeToken,
      referenceKey,
      prvKeyObj,
    } = payload;
    const cookies = yield getContext('cookies');
    const token = cookies.get('token');
    const tokenData = jwtDecode(token);


    if (!has(tokenData, 'encryptedHolderPassphrase')) {
      throw new LocalError({ code: 'encryptedHolderPassphrase' });
    }

    if (!has(tokenData, 'holderUID')) {
      throw new LocalError({ code: 'NoholderUID' });
    }

    const holderPassphrase = decryptEncodedEncryptedHolderPassphrase(tokenData.encryptedHolderPassphrase, prvKeyObj);
    const holderEntry = {
      holderUID: tokenData.holderUID,
      holderPassphrase,
    };

    const requestURL = '/api/Account/me/PatientProfile/StorageToken';
    yield call(ApiService.regionalRequest, requestURL, {
      method: 'POST',
      body  : {
        holderEntry,
        storageProvider,
        exchangeToken,
        referenceKey,
        storageAccount,
      },
    });
    yield put(actions.updatePatientProfileStorageTokenSuccess());

  } catch (err) {
    yield put(actions.updatePatientProfileStorageTokenError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* updatePatientProfile({ payload }) {
  try {
    const {
      storageProvider,
      storageAccount,
      encryptedExchangeToken,
      payer,
    } = payload;

    const requestUrl = '/api/Account/me/PatientProfile';
    const patientProfile = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : compactObject({
        storageProvider,
        storageAccount,
        encryptedExchangeToken,
        payer,
      }),
    });

    yield put(actions.updatePatientProfileSuccess(patientProfile));
  } catch (err) {
    yield put(actions.updatePatientProfileError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* reauthorizePatientProfileCloudDrive({ payload }) {
  try {
    const { authorizationCode } = payload;
    const patientProfile = yield select(selectors.patientProfile);
    const passphrase = yield select(selectors.passphrase);
    const keyPair = yield select(selectors.keyPair);
    const pubKeyObj = getKeyFromPem(keyPair.pubKeyPem);
    const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);
    const tokens = yield call(StorageExchangeTokenService.fetchExchangeToken,
      authorizationCode,
      'PatientProfile',
      patientProfile.patientProfileId);
    const { exchangeToken } = tokens;
    const encryptedExchangeToken = yield call(hybridEncrypt, exchangeToken, pubKeyObj);

    const updateProfileData = {
      encryptedExchangeToken,
    };
    const { storageProvider, storageAccount } = patientProfile;
    const { encryptedPhiSetReferenceKey } = patientProfile;
    const referenceKey = patientProfile.phiSetReferenceKey || decrypt(encryptedPhiSetReferenceKey, prvKeyObj);

    const updatePatientProfileStorageTokenData = {
      storageProvider,
      exchangeToken,
      referenceKey,
      storageAccount,
      prvKeyObj,
    };

    yield put(actions.updatePatientProfileStorageToken(updatePatientProfileStorageTokenData));
    const updatePatientProfileStorageTokenResult = yield take([
      actionTypes.UPDATE_PATIENT_PROFILE_STORAGE_TOKEN_SUCCESS,
      actionTypes.UPDATE_PATIENT_PROFILE_STORAGE_TOKEN_ERROR,
    ]);

    if (updatePatientProfileStorageTokenResult.type === actionTypes.UPDATE_PATIENT_PROFILE_STORAGE_TOKEN_ERROR) {
      yield put(actions.reauthorizePatientProfileCloudDriveError(updatePatientProfileStorageTokenResult.error));
      return;
    }

    yield put(actions.updatePatientProfile(updateProfileData));
    const updatePatientProfileResult = yield take([
      actionTypes.UPDATE_PATIENT_PROFILE_SUCCESS,
      actionTypes.UPDATE_PATIENT_PROFILE_ERROR,
    ]);

    if (updatePatientProfileResult.type === actionTypes.UPDATE_PATIENT_PROFILE_ERROR) {
      yield put(actions.reauthorizePatientProfileCloudDriveError(updatePatientProfileResult.error));
      return;
    }

    const updatedPatientProfile = { ...patientProfile, ...updateProfileData };
    updatedPatientProfile.accessToken = tokens;
    yield put(actions.reauthorizePatientProfileCloudDriveSuccess(updatedPatientProfile));
    yield put(App.actions.setAlert({
      type   : 'success',
      message: messages.alerts.cloudDriveReauthorized,
    }));
  } catch (err) {
    yield put(actions.reauthorizePatientProfileCloudDriveError(err));
    yield call(App.dispatchError, err, messages);
  } finally {
    yield put(CloudDrive.actions.clearAuthorizationCode());
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchCountrySettings() {
  try {
    const account = yield select(selectors.account);
    if (!account) {
      yield put(actions.fetchCountrySettingsSuccess());
      return;
    }
    const { countryId } = account;
    const countrySettings = yield call(CountryLocalizationService.fetchCountrySettings, countryId);
    if (countrySettings) {
      countrySettings.countryId = countryId;
    }
    yield put(actions.fetchCountrySettingsSuccess(
      countrySettings,
    ));
  } catch (err) {
    yield put(actions.fetchCountrySettingsError(err));
    yield call(App.dispatchError, err);
  }
}


function* fetchPayers() {
  try {
    const account = yield select(selectors.account);
    if (!account) {
      yield put(actions.fetchPayersSuccess());
      return;
    }
    const { countryId } = account;
    const payers = yield call(CountryLocalizationService.fetchPayers, countryId);
    yield put(actions.fetchPayersSuccess(payers));
  } catch (err) {
    yield put(actions.fetchPayersError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

/**
 * @deprecated since version 2.0
 */
function* updateCgProfile({ payload }) {
  try {
    const { avatar } = payload;
    const requestURL = '/api/Account/me/CgProfile';
    const cgProfile = yield call(ApiService.regionalRequest, requestURL, {
      method: 'PUT',
      body  : {
        avatar,
      },
    });
    yield put(actions.updateCgProfileSuccess(cgProfile));
  } catch (err) {
    yield put(actions.updateCgProfileError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchSupportedDevices(countryId) {
  const requestUrl = `/api/Localization/country/${countryId}/devices`;
  return yield call(ApiService.originalRequest, requestUrl);
}


function* fetchPatientProfileSupportedDevices() {
  try {
    const { countryId } = yield select(selectors.account);
    const supportedDevices = yield call(fetchSupportedDevices, countryId);
    yield put(actions.fetchPatientProfileSupportedDevicesSuccess(supportedDevices));
  } catch (err) {
    yield put(actions.fetchPatientProfileSupportedDevicesError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchAccessToken(encryptedExchangeToken, prvKeyObj, localError) {
  try {
    const exchangeToken = hybridDecrypt(encryptedExchangeToken, prvKeyObj);
    const accessToken = yield call(StorageExchangeTokenService.fetchAccessToken, exchangeToken);
    return { accessToken, exchangeToken };
  } catch (err) {
    if ((err.status === 404 || err.status === 409) && localError) {
      throw localError;
    } else {
      throw err;
    }
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* refreshPatientTokenWorker(patientProfile, expiresIn, exchangeToken, connectionLocalError) {
  if (!expiresIn) {
    return;
  }
  const interval = (expiresIn - (expiresIn / 4)) * 1000;
  while (true) {
    try {
      yield delay(interval);
      const accessToken = yield call(StorageExchangeTokenService.fetchAccessToken, exchangeToken);
      yield put(actions.updatePatientProfileSuccess({ ...patientProfile, accessToken }));
    } catch (err) {
      if ((err.status === 404 || err.status === 409) && connectionLocalError) {
        throw connectionLocalError;
      } else {
        throw err;
      }
    }
  }
}


function* connectToPatientStorage({ payload }) {
  try {
    const { patientProfile } = payload;
    const keyPair = yield select(selectors.keyPair);
    const passphrase = yield select(selectors.passphrase);
    const prvKeyObj = getKeyFromPem(keyPair.prvKeyPem, passphrase);

    if (!prvKeyObj) {
      throw new LocalError({ code: 'InvalidPassphrase' });
    }

    const { storageProvider } = patientProfile;
    const connectionLocalError = new LocalError({
      code   : 'PatientCloudDriveConnectionFailed',
      actions: [{
        action : CloudDrive.actions.authorize(storageProvider, 'pwdReAuth'),
        message: messages.buttons.reauthorizeCloudDrive,
      }],
    });

    // MIGRATION - CONVERT REFRESH TOKEN - START
    try {
      if (!patientProfile.encryptedExchangeToken && patientProfile.encryptedRefreshToken) {
        const pubKeyObj = getKeyFromPem(keyPair.pubKeyPem);
        patientProfile.encryptedExchangeToken = yield call(
          convertPatientProfileRefreshToken, patientProfile, prvKeyObj, pubKeyObj,
        );
      }
    } catch (err) {
      yield put(actions.connectToPatientStorageError(connectionLocalError));
      yield call(App.dispatchError, connectionLocalError, messages);
    }
    // MIGRATION - CONVERT REFRESH TOKEN - END

    const { encryptedExchangeToken, encryptedPhiSetReferenceKey } = patientProfile;

    const { accessToken, exchangeToken } = yield call(
      fetchAccessToken, encryptedExchangeToken, prvKeyObj, connectionLocalError,
    );
    const { expiresIn } = accessToken;

    const phiSetReferenceKey = decrypt(encryptedPhiSetReferenceKey, prvKeyObj);

    const connectedPatientProfile = {
      ...patientProfile,
      phiSetReferenceKey,
      accessToken,
    };

    yield put(actions.connectToPatientStorageSuccess(connectedPatientProfile));

    yield race([
      call(refreshPatientTokenWorker, connectedPatientProfile, expiresIn, exchangeToken, connectionLocalError),
      take(actionTypes.SIGN_OUT),
    ]);

  } catch (err) {
    yield put(actions.connectToPatientStorageError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

/**
 * @deprecated since version 2.0
 */
function* updateHcpProfile({ payload }) {
  try {
    const { avatar, customHcpIdentifiersValues } = payload;
    const requestURL = '/api/Account/me/HcpProfile';
    const hcpProfile = yield call(ApiService.regionalRequest, requestURL, {
      method: 'PUT',
      body  : {
        avatar,
        customHcpIdentifiersValues,
      },
    });
    yield put(actions.updateHcpProfileSuccess(hcpProfile));
  } catch (err) {
    yield put(actions.updateHcpProfileError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* fetchHcpCustomIdentifiers({ payload }) {
  try {
    const { countryId } = payload;
    const requestUrl = `/api/Localization/country/${countryId}/identifiers/Hcp`;
    const hcpCustomIdentifiers = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchHcpCustomIdentifiersSuccess(hcpCustomIdentifiers));
  } catch (err) {
    yield put(actions.fetchHcpCustomIdentifiersError(err));
    yield call(App.dispatchError, err);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchMemberships() {
  try {
    const hcpProfile = yield select(selectors.hcpProfile);
    if (!hcpProfile) {
      yield put(actions.fetchMembershipsSuccess([]));
    }
    const clinicMemberships = yield call(fetchClinicMemberships);
    yield put(actions.fetchMembershipsSuccess(clinicMemberships));
  } catch (err) {
    yield put(actions.fetchMembershipsError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* createClinicForOrganizationMembership(organizationMembership) {
  const { organization, hasClinic } = organizationMembership;
  const { organizationUID, publicKey } = organization;

  let clinic;
  if (!hasClinic) {
    const pubKeyObj = getKeyFromPem(publicKey);
    const encryptedProfilesReferenceKey = encrypt(uuidv4(), pubKeyObj);
    clinic = {
      encryptedProfilesReferenceKey,
    };
  }

  const requestUrl = hasClinic ? '/api/ClinicMembership' : '/api/Clinic';
  yield call(ApiService.regionalRequest, requestUrl, {
    method: 'POST',
    body  : {
      organizationUID,
      clinic,
    },
  });

}


function* validateMemberships() {
  const organizationMembershipsWithoutClinics = yield select(selectors.organizationMembershipsWithoutClinics);
  if (organizationMembershipsWithoutClinics.length === 0) {
    yield put(actions.validateMembershipsSuccess());
    return;
  }
  const calls = map(organizationMembershipsWithoutClinics, (organizationMembership) => (
    call(createClinicForOrganizationMembership, organizationMembership)
  ));
  try {
    yield all(calls);
  } catch (err) {
    yield put(actions.validateMembershipsError(err));
    yield call(App.dispatchError, err);
  }

  yield put(actions.fetchMemberships());
  const newClinicMembershipsResult = yield take([
    actionTypes.FETCH_MEMBERSHIPS_SUCCESS,
    actionTypes.FETCH_MEMBERSHIPS_ERROR,
  ]);
  if (newClinicMembershipsResult.type === actionTypes.FETCH_MEMBERSHIPS_ERROR) {
    const err = newClinicMembershipsResult.error;
    yield put(actions.validateMembershipsError(err));
    yield call(App.dispatchError, err, messages, null, true);
  }
  const { clinicHcpMemberships } = newClinicMembershipsResult.payload;
  const newClinicMembership = find(clinicHcpMemberships, (clinicMembership) => (
    clinicMembership.clinic.organizationUID === head(organizationMembershipsWithoutClinics).organization.organizationUID
  ));
  if (newClinicMembership) {
    yield put(actions.setActiveClinicMembershipId(
      newClinicMembership.clinicHcpMembershipId,
      newClinicMembership.clinic.organizationUID,
    ));
  }
  yield put(actions.validateMembershipsSuccess());
}

//----------------------------------------------------------------------------------------------------------------------

function* getOrganizationMembership(organizationUID) {
  const organizationMemberships = yield select(Information.selectors.organizationMemberships);
  const organizationMembership = find(
    organizationMemberships,
    (om) => om.organization.organizationUID === organizationUID,
  );
  if (!organizationMembership) {
    throw new LocalError({ code: 'NoOrganizationMembership' });
  }
  return organizationMembership;
}


function* refreshClinicTokenWorker(clinicHcpMembershipId, expiresIn, exchangeToken, connectionLocalError) {
  if (!expiresIn) {
    return;
  }
  const interval = (expiresIn - (expiresIn / 4)) * 1000;
  while (true) {
    try {
      yield delay(interval);
      const accessToken = yield call(StorageExchangeTokenService.fetchAccessToken, exchangeToken);
      yield put(actions.connectToClinicSuccess({ clinicHcpMembershipId, accessToken }));
    } catch (err) {
      if ((err.status === 404 || err.status === 409) && connectionLocalError) {
        throw connectionLocalError;
      } else {
        throw err;
      }
    }
  }
}


function* connectToClinic({ payload }) {
  const { clinicMembership } = payload || {};
  const { clinicHcpMembershipId, clinic } = clinicMembership || {};

  try {
    const redirect = yield getContext('redirect');
    const getUrl = yield getContext('getUrl');
    const { encryptedExchangeToken, isAdmin } = clinicMembership;

    if (!clinic.storageAccount) {
      if (isAdmin) {
        const url = yield call(getUrl, 'add-drive');
        yield call(redirect, url);
      }
      throw new LocalError({ code: 'NoStorageProvider' });
    }

    const { organizationUID } = clinic;
    const accountKeyPair = yield select(selectors.keyPair);
    const accountPassphrase = yield select(selectors.passphrase);
    const accountPrvKeyObj = getKeyFromPem(accountKeyPair.prvKeyPem, accountPassphrase);

    if (!accountPrvKeyObj) {
      throw new LocalError({ code: 'InvalidPassphrase' });
    }

    const organizationMembership = yield call(getOrganizationMembership, organizationUID);
    const passphrase = decrypt(organizationMembership.encryptedPassphrase, accountPrvKeyObj);
    const clinicPrvKeyObj = getKeyFromPem(organizationMembership.encryptedPrivateKey, passphrase);
    const profilesReferenceKey = decrypt(clinic.encryptedProfilesReferenceKey, clinicPrvKeyObj);

    if (!profilesReferenceKey) {
      const err = new LocalError({ code: 'NoProfilesReferenceKey' });
      yield put(actions.connectToClinicError(err));
      yield call(App.dispatchError, err, messages);
      return;
    }

    // MIGRATION - CONVERT REFRESH TOKEN - START
    // if (!clinicMembership.encryptedExchangeToken && clinicMembership.encryptedRefreshToken) {
    //   const accountPubKeyObj = getKeyFromPem(accountKeyPair.pubKeyPem);
    //   clinicMembership.encryptedExchangeToken = yield call(
    //     convertClinicMembershipRefreshToken, clinicMembership, accountPrvKeyObj, accountPubKeyObj,
    //   );
    // }
    // MIGRATION - CONVERT REFRESH TOKEN - END

    const connectionLocalError = new LocalError({
      code  : isAdmin ? 'ClinicCloudDriveConnectionFailedForAdmin' : 'ClinicCloudDriveConnectionFailed',
      params: { clinicName: clinic.name },
    });

    if (isAdmin) {
      connectionLocalError.actions = [{
        action: CloudDrive.actions.authorize(
          clinic.storageProvider,
          encodeURI(`clinicReAuth-${organizationUID}-${clinic.name}`),
        ),
        message: messages.buttons.reauthorizeCloudDrive,
      }];
    }


    const eet = clinic.encryptedExchangeToken || encryptedExchangeToken;
    if (!eet) {
      throw connectionLocalError;
    }

    const prvKeyObj = clinic.encryptedExchangeToken ? clinicPrvKeyObj : accountPrvKeyObj;

    const [
      { accessToken, exchangeToken },
      supportedDevices,
    ] = yield all([
      call(fetchAccessToken, eet, prvKeyObj, connectionLocalError),
      call(fetchSupportedDevices, clinic.countryId),
    ]);

    const { expiresIn } = accessToken;

    const connectionData = {
      clinicHcpMembershipId,
      accessToken,
      passphrase,
      profilesReferenceKey,
      supportedDevices,
    };

    yield put(actions.connectToClinicSuccess(connectionData));

    yield race([
      call(refreshClinicTokenWorker, clinicHcpMembershipId, expiresIn, exchangeToken, connectionLocalError),
      take(actionTypes.DISCONNECT_CLINIC),
      take(actionTypes.SIGN_OUT),
    ]);
  } catch (err) {
    if (err instanceof LocalError) {
      const businessError = err.getBusinessError();
      if (businessError.code === 'ClinicCloudDriveConnectionFailed' && clinic) {
        const { clinicId, name: clinicName } = clinic;
        yield call(NotificationsService.sendInvalidClinicCloudDriveAccessNotification, { clinicId, clinicName });
      }
    }
    yield put(actions.connectToClinicError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* gdprFetchAccountDump() {
  try {
    const requestUrl = '/api/Account/me/dump';
    const account = yield call(ApiService.regionalRequest, requestUrl);
    yield put(actions.gdprFetchAccountDumpSuccess(account));
  } catch (err) {
    yield put(actions.gdprFetchAccountDumpError(err));
  }
}


function* gdprSaveToFile() {
  try {
    const gdprData = yield select(selectors.gdprData);
    const zip = new JSZip();
    const {
      account, phiSet,
      readingsMonthlyBatches, cgmReadingsMonthlyBatches, measurementsMonthlyBatches, imports,
    } = gdprData;

    zip.file('account.json', JSON.stringify(account));
    if (phiSet) {
      zip.file('phiSet.json', JSON.stringify(phiSet));
    }
    if (readingsMonthlyBatches && readingsMonthlyBatches.length) {
      zip.file('readingsMonthlyBatches.json', JSON.stringify(readingsMonthlyBatches));
    }
    if (cgmReadingsMonthlyBatches && cgmReadingsMonthlyBatches.length) {
      zip.file('cgmReadingsMonthlyBatches.json', JSON.stringify(cgmReadingsMonthlyBatches));
    }
    if (measurementsMonthlyBatches && measurementsMonthlyBatches.length) {
      zip.file('measurementsMonthlyBatches.json', JSON.stringify(measurementsMonthlyBatches));
    }
    if (imports && imports.length) {
      zip.file('imports.json', JSON.stringify(imports));
    }

    const content = yield zip.generateAsync({ type: 'blob' });
    saveAs(content, 'exportData.zip');
    yield put(actions.gdprSaveToFileSuccess());
  } catch (err) {
    yield put(actions.gdprSaveToFileError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* updateNotificationsSettings({ payload }) {
  try {
    const { notificationsSettings } = payload;
    const notifications = {};
    Object.keys(notificationsSettings).forEach((notificationSetting) => {
      notifications[notificationSetting] = notificationsSettings[notificationSetting] === 'true';
    });
    const requestUrl = '/api/Account/me/settings/notifications';
    const response = yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { notifications },
    });
    yield put(actions.updateNotificationsSettingsSuccess(response.notifications));
  } catch (err) {
    yield put(actions.updateNotificationsSettingsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------


function* sagas() {
  yield takeLatest(actionTypes.GET_ME, getMe);
  yield takeLatest(actionTypes.AUTH_CALLBACK, authCallback);
  yield takeLatest(actionTypes.FETCH_TOKEN, fetchToken);
  yield takeLatest(actionTypes.GET_CC_ACCESS_TOKEN, getCCAccessToken);
  yield takeLatest(actionTypes.CHANGE_AID_PASSWORD, changeAidPassword);
  yield takeLatest(actionTypes.EDIT_AID_ACCOUNT, editAidAccount);
  yield takeLatest(actionTypes.ADD_AID_ORGANIZATION_MEMBERSHIP, addAidOrganizationMembership);
  yield takeLatest(actionTypes.MANAGE_AID_ORGANIZATION_MEMBERSHIPS, manageAidOrganizationMemberships);
  yield takeLatest(actionTypes.VALIDATE_ACCOUNT, validateAccount);
  yield takeLatest(actionTypes.CHECK_PERMISSIONS, checkPermissions);
  yield takeLatest(actionTypes.SIGN_IN, signIn);
  yield takeLatest(actionTypes.SIGN_OUT, signOut);
  yield takeLatest(actionTypes.ELEVATE_PERMISSIONS, elevatePermissions);
  yield takeLatest(actionTypes.FETCH_PATIENT_DATA_FROM_INVITATION, fetchPatientDataFromInvitation);
  yield takeLatest(actionTypes.FETCH_PATIENT_HEALTH_DATA_FROM_INVITATION, fetchPatientHealthDataFromInvitation);
  yield takeLatest(actionTypes.GENERATE_KEYS_PEM, generateKeysPem);
  yield takeLatest(actionTypes.GET_PASSPHRASE, getPassphrase);
  yield takeLatest(actionTypes.SWITCH_PROFILE, switchProfile);
  yield takeLatest(actionTypes.SET_ACTIVE_CLINIC_MEMBERSHIP_ID, setActiveClinicMembershipId);
  yield takeLatest(actionTypes.UPDATE_ACCOUNT_SETTINGS, updateAccountSettings);
  yield takeLatest(actionTypes.DELETE_ACCOUNT, deleteAccount);
  yield takeLatest(actionTypes.UPDATE_PATIENT_PROFILE_STORAGE_TOKEN, updatePatientProfileStorageToken);
  yield takeLatest(actionTypes.UPDATE_PATIENT_PROFILE, updatePatientProfile);
  yield takeLatest(actionTypes.REAUTHORIZE_PATIENT_PROFILE_CLOUD_DRIVE, reauthorizePatientProfileCloudDrive);
  yield takeLatest(actionTypes.FETCH_COUNTRY_SETTINGS, fetchCountrySettings);
  yield takeLatest(actionTypes.FETCH_PAYERS, fetchPayers);
  yield takeLatest(actionTypes.FETCH_PATIENT_PROFILE_SUPPORTED_DEVICES, fetchPatientProfileSupportedDevices);
  yield takeLatest(actionTypes.CONNECT_TO_PATIENT_STORAGE, connectToPatientStorage);
  yield takeLatest(actionTypes.UPDATE_CG_PROFILE, updateCgProfile);
  yield takeLatest(actionTypes.UPDATE_HCP_PROFILE, updateHcpProfile);
  yield takeLatest(actionTypes.FETCH_HCP_CUSTOM_IDENTIFIERS, fetchHcpCustomIdentifiers);
  yield takeLatest(actionTypes.FETCH_MEMBERSHIPS, fetchMemberships);
  yield takeLatest(actionTypes.VALIDATE_MEMBERSHIPS, validateMemberships);
  yield takeLatest(actionTypes.CONNECT_TO_CLINIC, connectToClinic);
  yield takeLatest(actionTypes.GDPR_FETCH_ACCOUNT_DUMP, gdprFetchAccountDump);
  yield takeLatest(actionTypes.GDPR_SAVE_TO_FILE, gdprSaveToFile);
  // TODO: Remove if confirmed
  // yield takeLatest(actionTypes.AUTH_CONTOUR_CLOUD, authContourCloud);
  yield takeLatest(actionTypes.UPDATE_NOTIFICATIONS_SETTINGS, updateNotificationsSettings);
}


export default [sagas];
